<div class="card">
  <img class="noise" src="/assets/noise/auth-noise.svg" />

  <header>
    <div class="header-title">
      <img src="/assets/duplicati/duplicati.svg" alt="" />
      Duplicati
    </div>
  </header>

  <main>
    <ng-content></ng-content>

    <footer>
      <ng-content select="[footer]" />
    </footer>
  </main>
</div>

<div class="bg"></div>
